<template>
    <div class="header">
        <div class="wrap">
            <h1 class="logo">
                <a href="/" title="锐眼视界">
                    <img src="/images/logo.svg" alt="锐眼视界" width="152" height="42" />
                </a>
            </h1>

            <div class="operation">
                <div class="nav">
                    <a href="/" :class="{'current': navCurrent === 'index'}">首页</a>
                    <a href="/interface" :class="{'current': navCurrent === 'interface'}">数据API</a>
                    <a href="/about/home" :class="{'current': navCurrent === 'about'}">关于我们</a>
                </div>

                <div class="vip">
                    <el-button type="text" v-track:click track-params="t:click_homepage_vipservice" @click="handleToVip"><i class="el-icon-vip el-icon--left"></i>服务</el-button>
                </div>

                <div class="account">
                    <div v-if="userInfo.weiXinUnionId">
                        <el-dropdown @command="handleAccountCommand">
                            <div class="el-dropdown-link" @click="$router.push('/user')">
                                <el-avatar :size="30" :src="userInfo.weiXinHeadImgUrl"></el-avatar>
                                <span>{{ userInfo.nickName }}</span>
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </div>

                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="user">个人中心</el-dropdown-item>
                                <el-dropdown-item command="exit">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div v-else>
                        <el-button type="text" @click="handleToLogin">登录</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" @click="handleToLogin">注册</el-button>
                    </div>
                </div>
            </div>
        </div>

        <Login ref="Login" @success="handleLoginSuccess" />
        <BuyVip ref="BuyVip" @confirm="handlePayConfirm" />
        <OrderPay ref="OrderPay" @success="handlePaySuccess" />
    </div>
</template>

<script>
import commonMixins from '/src/mixins/commonMixins';

export default {
    name: 'HeaderBar',
    mixins: [commonMixins],
    data() {
        return {
            avatarUrl: '',

            keywords: '',

            navData: [
                { url: '/', name: '首页' },
                { url: '/about', name: '关于我们' },
            ],
        };
    },
    watch: {
        '$route': {
            handler() {
                // console.log('route change');
                this.keywords = '';
            }
        },
    },
    computed: {
        routeName () {
            return this.$route.name || '';
        },
        navCurrent () {
            return this.$route.meta?.navCurrent || '';
        },
    },
    // mounted() {
    //     console.log(this.$route.meta.navCurrent);
    // },
    methods: {
        handleAccountCommand(command) {
            // console.log(command);
            switch(command) {
                case 'user':
                    this.$router.push('/user');
                    break;
                case 'exit':                    
                    this.$confirm('是否确认退出登录?', '退出登录', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('FedLogOut');

                        this.$router.push('/');
                    }).catch(() => {});
                    break;
                default:
                    break;
            }
        },

        // 登录
        handleToLogin() {
            this.$refs.Login.handleOpen();
        },

        // 升级vip
        handleToVip() {
            // const { userInfo, vipFlag } = this;
            
            // if (!userInfo.weiXinUnionId) {
            //     // 未登录
            //     this.$refs.Login.handleOpen();
            // } else if (!vipFlag) {
            //     // 开通会员
            //     this.$refs.BuyVip.handleOpen();
            // }
            
            // this.$router.push('/membership/index');

            // 延迟一点跳转，有数据上报操作
            setTimeout(() => {
                window.location.href = '/membership/index';
            }, 300);
        },
    },
};
</script>

<style lang="scss" scoped>
    .el-dropdown-link {
        cursor: pointer;
    }
</style>