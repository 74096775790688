<template>
    <router-view :key="key" v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
</template>

<script>
export default {
    name: 'AppMain',
    computed: {
        key() {
            return this.$route.fullPath;
        },
    },
};
</script>

<style lang="scss" scoped></style>
